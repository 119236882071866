export default {
  props: {
    /**
     * Max file size in MB
     */
    maxSize: {
      type: Number,
      default: 2 * 1.8, // Compressor 80%
    },
  },

  methods: {
    /**
     * Validates and convert file result to blob
     * @param  {File} file
     * @return {Promise}
     */
    toBase64(file) {
      return new Promise((resolve, reject) => {
        /**
         * Check file size and type
         */
        if (!file) {
          reject(new Error('Photo is required'))
        }

        // if (this.maxSize && this.toMb(file.size) > this.maxSize) {
        //   reject(
        //     new Error(`Photo size must be equal or less than ${this.maxSize}MB`)
        //   )
        // }

        if (!this.isImage(file)) {
          reject(new Error('Photo must be an image'))
        }

        const reader = new FileReader()
        reader.onload = (e) => {
          resolve({
            file: file,
            url: e.target.result,
          })
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },

    /**
     * Convert Bytes to MB
     * @param  {Number} size
     * @return {Number}
     */
    toMb(size) {
      return size ? size / 1024 / 1024 : 0
    },

    isImage(file) {
      return file && file.type.split('/')[0] === 'image'
    },
  },
}
