<template>
  <div
    class="file-upload"
    :class="{
      'file-upload--banner': banner,
    }"
  >
    <div class="file-upload__border">
      <div class="file-upload__image">
        <UploadedImage
          :image="image"
          class="file-upload__image__view"
          @select-image="onSelectImage"
        />
        <Loading v-model="loading" />
      </div>
      <div class="file-upload__menus" v-if="showUploadImageBtn">
        <UploadButton
          :disabled="loading"
          :aspect-ratio="aspectRatio"
          :initial-aspect-ratio="initialAspectRatio"
          :disable-cropping="disableCropping"
          :maxWidth="maxWidth"
          :maxHeight="maxHeight"
          :width="width"
          :height="height"
          @input="onChange"
          class="mb-4"
        />
        <v-btn
          v-if="!disableDelete"
          text
          class="file-upload__menus__delete text-capitalize font-weight-bold text-light-grey"
          :loading="loading"
          :disabled="loading || !hasImage"
          @click="onDelete"
        >
          <img src="@/assets/icons/icon-trash.svg" class="mr-2" alt />Delete
          Image
        </v-btn>
      </div>
    </div>

    <ErrorMessage
      v-if="error"
      :error="error"
      display="sentence"
      class="file-upload__error"
    />
    <ViewImage v-model="showImage" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * File Multiple Upload
 * ==================================================================================
 **/
import SnackbarMixin from '@/utils/mixins/Snackbar'
import FileUploadMixin from '@/utils/mixins/FileUpload'
import ImageCropperMixin from '@/utils/mixins/ImageCropper'
import UploadedImage from '@/components/fields/FileUpload/UploadedImage'
import UploadButton from '@/components/fields/FileUpload/UploadButton'
import Loading from '@/components/common/Loading'
import ViewImage from '@/components/modals/ViewImage'
import ErrorMessage from '@/components/fields/ErrorMessage'

export default {
  components: {
    UploadedImage,
    UploadButton,
    Loading,
    ViewImage,
    ErrorMessage,
  },

  mixins: [SnackbarMixin, FileUploadMixin, ImageCropperMixin],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    banner: {
      type: Boolean,
      default: false,
    },

    disableDelete: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [String, Array],
      default: null,
    },

    maxWidth: {
      type: Number,
    },

    maxHeight: {
      type: Number,
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },

    showUploadImageBtn: {
      type: Boolean,
      default: true,
    },

    disableCropping: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showImage: false,
    }
  },

  computed: {
    hasImage() {
      return !!this.image
    },
  },

  methods: {
    onChange(image) {
      this.$emit('input', image)
    },

    onDelete() {
      this.$emit('delete', this.image)
    },

    onSelectImage(image) {
      this.showImage = image
    },
  },
}
</script>
<style lang="scss" scoped>
$size: 140px;
.file-upload {
  width: 100%;

  &__border {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &__image {
    width: 100%;
    max-width: $size;
    height: $size;
    position: relative;
    border-radius: 12px;
    background: lightgray;
    margin-right: 10px;
    overflow: hidden;

    ::v-deep &__view {
      width: inherit;
      height: inherit;
    }
  }

  &__menus {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__error {
    margin-top: 5px;
  }
}

.file-upload.file-upload--banner {
  .file-upload__image {
    max-width: $size * 2;
  }
}
</style>
