<template>
  <div class="news-banner-upload">
    <FileUpload
      :image="form.banner"
      :aspect-ratio="1.77"
      :initial-aspect-ratio="1.77"
      :banner="true"
      :loading="form.$busy || loading"
      :error="error"
      @input="toUpload"
      @delete="toDelete"
      :showUploadImageBtn="showUploadImageBtn"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * News: Banner Upload
 * ==================================================================================
 **/

import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import FileUpload from '@/components/fields/FileUpload'

export default {
  name: 'NewsBannerUpload',
  components: {
    FileUpload,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    forUpdate: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [String, Array],
      default: null,
    },

    showUploadImageBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: new Form({
        banner: null,
      }),
    }
  },

  computed: {
    ...mapState({
      news: (state) => state.news.newsDetails,
    }),
  },

  watch: {
    news: {
      handler(newValue, oldValue) {
        this.initForm()
      },
      deep: true,
    },
  },

  mounted() {
    this.initForm()
  },

  methods: {
    ...mapActions({
      uploadImage: 'news/uploadNewsImage',
      deleteImage: 'news/deleteNewsImage',
    }),

    initForm() {
      if (this.news) {
        this.form.banner = this.news.banner
      }
    },

    async toUpload(image) {
      if (!this.forUpdate) {
        this.form.banner = image
      } else {
        if (this.form.$busy) return

        this.form.$busy = true
        await this.uploadImage(this.getUploadFormData(image))
          .then(() => {
            this.showSnackbar('Banner uploaded successfully!')
          })
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.form.$busy = false
          })
      }

      this.$emit('updated')
    },

    async toDelete(image) {
      if (!this.forUpdate) {
        this.form.banner = null
      } else {
        if (this.form.$busy) return

        this.form.$busy = true
        await this.deleteImage(this.getDeleteFormData(image))
          .then(() => {
            this.showSnackbar('Banner deleted successfully!')
          })
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.form.$busy = false
          })
      }
    },

    getUploadFormData(image) {
      let form = new FormData()
      form.append('id', this.news.id)
      form.append('images[]', image.file)
      form.append('image_name', 'banner')

      return form
    },

    getDeleteFormData(image) {
      return {
        id: image.id,
        image_name: 'banner',
      }
    },

    getImage() {
      return this.form.banner ? this.form.banner.file : null
    },

    imageCraft() {
      return this.form.banner
    },

    hasData() {
      return !!this.getImage()
    },
  },
}
</script>
<style lang="scss" scoped>
.news-banner-upload {
  width: 100%;
}
</style>
